<template>
  <div class="activities-detail-page">
    <div
      class="card"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <el-row :gutter="20">
        <el-col :span="20">
          <div class="text-grey-dark title-md text-bold">
            {{ info.title }}
          </div>
          <div class="text-grey-dark text-md text-bold mt-20">
            {{ $t("activity_des") }}：<span class="text-black-light">{{
              info.summary
            }}</span>
          </div>
          <div class="text-grey-dark text-md text-bold mt-10">
            {{ $t("reword_gold") }}：<span class="text-black-light"
              >{{ info.minPoints }} - {{ info.maxPoints }}</span
            >
          </div>
          <div class="text-grey-dark text-md text-bold mt-10">
            {{ $t("activity_time") }}：<span class="text-black-light"
              >{{ info.startDate }} - {{ info.endDate }}</span
            >
          </div>
        </el-col>
        <el-col :span="4">
          <el-button class="mt-20" type="primary" round @click="takeIn(info)">{{
            $t("takein_activity")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card">
      <div class="text-grey-dark title-md text-bold">
        {{ $t("activity_content") }}
      </div>
      <div class="mt-20" v-html="info.contents"></div>
    </div>
  </div>
</template>
<style src="./detail.scss" lang="scss"></style>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      info: {},
      loading: false
    };
  },
  async created() {
    this.loading = true;
    const res = await this.$http.get(
      `/api/v1/Activities/${this.$route.params.id}`
    );
    if (res.status !== 200) {
      this.$message.error(res.statusText);
    }
    this.info = res.data;
    this.loading = false;
  },
  methods: {
    ...mapActions(["setDialogVisible"]),

    takeIn(a) {
      if (!this.account.id) {
        return this.setDialogVisible({ login: true });
      }
      if (a.url) {
        window.open(a.url);
      } else {
        this.$router.push("/center/recommend");
      }
    }
  },
  computed: {
    ...mapGetters(["account"])
  }
};
</script>
